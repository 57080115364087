@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/z-index";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/variables/product";

.header {
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
  background-color: $color-web-background-default;
  display: block;
  z-index: $z-index-second;
  position: relative;

  @include breakpoint-large-up {
    margin: 0 auto;
  }
}

.headerContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include breakpoint-large-up {
    display: flex;
    align-items: center;
    max-width: 128rem;
    height: 7.2rem;
    padding: 0 $spacing-fiftysix;
    margin: 0 auto;
  }
}

.upperHeaderContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include breakpoint-large-up {
    display: flex;
    height: 100%;
    align-items: center;
    max-width: 128rem;
    padding: 0 $spacing-fiftysix;
    margin: 0 auto;
  }
}

.divider {
  display: none;

  @include breakpoint-large-up {
    display: block;
    width: 100%;
    height: 0.1rem;
    margin: 0;
    border: none;
    background-color: $color-web-divider-primary;
  }
}

.logo {
  padding-left: $spacing-sixteen;
  display: flex;
  align-items: center;
  height: 6.4rem;

  @include breakpoint-medium-up {
    padding-left: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding-left: 0;
    margin-right: $spacing-sixteen;
  }

  @include breakpoint-larger-up {
    margin-right: $spacing-forty;
    padding-left: 0;
  }

  img {
    padding-bottom: 0.8rem;

    @if $product == se {
      padding-bottom: 0;
      height: 2.3rem;

      @include breakpoint-large-up {
        height: auto;
      }
    }
  }
}

.buttonsBar {
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-right: 1.6rem;
  gap: 0.8rem;

  @include breakpoint-medium-up {
    margin-right: 2.4rem;
    gap: 1.6rem;
  }

  @include breakpoint-large-up {
    order: 1;
    margin-right: 0;
    margin-left: $spacing-eight;
  }

  @include breakpoint-larger-up {
    margin-right: 0;
  }
}

.mainNav {
  display: none;
  width: 100%;

  @include breakpoint-large-up {
    display: inline-flex;
    align-items: center;
    height: 100%;
    flex: 1 1;
  }

  .itemWrapper {
    height: 100%;

    @include breakpoint-large-up {
      margin-left: $spacing-sixteen;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: auto;
      }
    }

    @include breakpoint-larger-up {
      margin-left: $spacing-twentyfour;
    }
  }

  &.expanded {
    display: block;

    @include breakpoint-large-up {
      display: inline-flex;
    }
  }
}

.menuButton {
  @include font-semi-bold;

  position: relative;
  box-shadow: none;
  border: none;
  border-radius: 1rem;
  color: $color-button-secondary-contents-default;
  background-color: $color-button-secondary-background-default;
  line-height: 1.8rem;
  padding: 0 $spacing-twentyfour;
  height: 3.2rem;
  cursor: pointer;

  @include breakpoint-medium-up {
    padding: 0.5rem $spacing-sixteen 0.5rem 3.8rem;
  }

  @include breakpoint-large-up {
    display: none;
  }

  &:hover {
    color: $color-button-secondary-contents-hovered;
    background-color: $color-button-secondary-background-hovered;
  }

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    width: 1.6rem;
    height: 0.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.6rem;
    background-color: $color-button-secondary-contents-default;
  }

  &::after {
    content: "";
    box-sizing: border-box;
    display: block;
    width: 1.6rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.6rem;
    border-top: 0.2rem solid $color-button-secondary-contents-default;
    border-bottom: 0.2rem solid $color-button-secondary-contents-default;
  }

  .menuButtonLabel {
    display: none;

    @include breakpoint-medium-up {
      display: inline-block;
    }
  }

  &.close {
    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      border: none;
      height: 0.2rem;
      background-color: $color-action-icon-default;
      transform: translateY(-50%) rotate(315deg);
      top: 1.6rem;
    }
  }
}

.navButton::after {
  @include breakpoint-medium-only {
    bottom: 2rem;
  }
}

.externalMenu {
  color: $color-action-icon-default;
  fill: $color-action-icon-default;

  .itemWrapper:last-child {
    color: $color-action-icon-default;
  }
}

.searchButton {
  @include font-semi-bold;

  display: flex;
  align-items: center;
  box-shadow: none;
  border: none;
  border-radius: 1rem;
  color: $color-button-secondary-contents-default;
  background-color: $color-button-secondary-background-default;
  height: 3.2rem;
  cursor: pointer;
  padding: 0 $spacing-sixteen;

  @include breakpoint-large-up {
    display: none;
  }

  &:hover {
    color: $color-button-secondary-contents-hovered;
    background-color: $color-button-secondary-background-hovered;
  }
}

.searchInputContainer {
  display: none;

  @include breakpoint-large-up {
    display: block;
    margin: $spacing-twelve 0;
    position: relative;
  }
}

.magnifyIcon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
  stroke: $color-button-secondary-contents-default;
}

.searchButtonIcon {
  width: 1.6rem;
  stroke: $color-button-primary-outline-contents-default;
}

.clearButton {
  background-color: white;
  border-color: white;
  fill: black;

  &.clearButton {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    margin-top: -1rem;
    right: 1.2rem;

    &:hover {
      cursor: pointer;
      background-color: $color-core-grey-light-3;
    }
  }
}

.input {
  &.input {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    height: 4.8rem;
  }

  &::placeholder {
    font-weight: 400;
    color: $color-text-hint;
  }
}

@media print {
  .header {
    display: none;
  }
}
