@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/variables/breakpoints";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/images";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/underline";
@import "~@styles/utils/variables/product";

$bmi-max-width: 792px;

.container {
  display: flex;
  position: relative;

  @include breakpoint-large-up {
    max-width: $bmi-max-width;
    margin: 0 auto;
  }

  &.full-width {
    background-color: $color-web-background-secondary;
    max-width: none;
    padding-top: $spacing-fortyeight;
    padding-bottom: $spacing-fortyeight;
    clear: both;

    @include breakpoint-medium-up {
      padding-top: $spacing-sixtyfour;
      padding-bottom: $spacing-sixtyfour;
    }

    @include breakpoint-large-up {
      padding-top: $spacing-eighty;
      padding-bottom: $spacing-eighty;
    }
  }
}

.boxWrapper {
  position: relative;
  max-width: 100%;
  background-color: $color-web-background-secondary;
  border-radius: 2rem;

  @include breakpoint-large-up {
    border-radius: 3rem;
  }

  .full-width & {
    margin: 0 auto;
    max-width: $bmi-max-width;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 $spacing-eight $spacing-eight;
}

.title {
  @include responsive-title-4;

  text-align: center;
  padding-bottom: $spacing-eight;
  margin-top: $spacing-forty;

  @include breakpoint-medium-up {
    padding-bottom: $spacing-twentyfour;
    margin-top: $spacing-forty;
  }

  @include breakpoint-large-up {
    margin-top: $spacing-sixtyfour;
  }

  &::before {
    @include underline;

    content: "";
    display: block;
    margin: 0 auto $spacing-eight;

    @include breakpoint-medium-up {
      margin-bottom: $spacing-sixteen;
    }

    @include breakpoint-large-up {
      margin-bottom: $spacing-twentyfour;
    }
  }

  &--full-width {
    margin-top: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
  }
}

.subtitle {
  @include text-16-regular;

  text-align: center;
  padding: 0 $spacing-sixteen;

  @include breakpoint-small-up {
    padding: 0 $spacing-fortyeight;
  }

  @include breakpoint-medium-up {
    @include text-20-regular;

    padding: 0 10.8rem;

    @if $product == se {
      padding: 0 10rem;
    }
  }

  .full-width & {
    margin: 0;
  }
}

.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-web-background-secondary;
  margin-bottom: $spacing-thirtytwo;

  @include breakpoint-large-up {
    margin-bottom: $spacing-fiftysix;
  }

  .full-width & {
    margin: 0;
  }

  &--expanded {
    margin-bottom: 0;
  }
}

.calcInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $spacing-sixteen;
  padding: $spacing-twentyfour $spacing-sixteen 0;
  text-align: center;
  gap: $spacing-sixteen;

  @include breakpoint-medium-up {
    padding-top: $spacing-thirtytwo;
  }
}

.calcTitle {
  @include responsive-title-5;

  text-align: center;
  background-color: $color-core-white;
  border-radius: 1.2rem;
  padding: $spacing-eight $spacing-sixteen;
}

.calcSubtitle {
  @include text-16-semi-bold;

  @include breakpoint-medium-up {
    @include text-20-semi-bold;
  }
}

.warning {
  text-align: left;
  font-size: 1.8rem;
  font-weight: normal;
}

.calcCTA {
  @include text-16-semi-bold;

  align-self: center;
  padding: $spacing-twelve $spacing-twentyfour;
  margin: 0;

  @include breakpoint-medium-up {
    @include text-20-semi-bold;

    padding: $spacing-sixteen $spacing-fortyeight;
  }

  @include breakpoint-large-up {
    border-radius: 1.6rem;
  }
}

.joinCTA {
  margin-bottom: $spacing-twentyfour;

  @include breakpoint-large-up {
    margin-bottom: $spacing-thirtytwo;
  }
}

.inputSection {
  display: flex;
  flex-direction: column;
  margin: $spacing-twentyfour auto;
  align-items: flex-end;

  @include breakpoint-medium-up {
    margin: $spacing-thirtytwo auto;
    flex-direction: row;
    width: 86%;
    align-items: center;
  }

  label {
    margin-bottom: 0;
  }

  input {
    width: 7.9rem;
  }
}

.inputContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;

  @include breakpoint-medium-up {
    margin: 0;
    width: 33.333%;
  }
}

.input {
  margin: 0 1rem;
  text-align: center;
  border-radius: 1.2rem;
}

.inputUnit {
  min-width: 3rem;
}

.successDiscalimer {
  @include text-16-regular;

  width: 100%;
  text-align: center;

  @include breakpoint-medium-up {
    @include text-20-regular;
  }
}

.disclaimer {
  font-size: 1.2rem;
  padding: 0 $spacing-sixteen $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: 0 $spacing-twentyfour $spacing-twentyfour;
  }
}
