@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";

.container {
  background-color: $color-button-secondary-background-default;
  border: 0.1rem solid $color-button-secondary-background-default;
  color: $color-button-secondary-contents-default;

  @include breakpoint-large-up {
    border-radius: 1.6rem;
  }

  &:hover {
    background-color: $color-button-secondary-background-hovered;
    border: 0.1rem solid $color-button-secondary-background-hovered;
    color: $color-button-secondary-contents-hovered;
  }
}
